import React, { Suspense } from "react";
import { Marker, Popup } from "react-leaflet";
import { Icon as LIcon, divIcon as LdivIcon, point as Lpoint } from "leaflet";
import { Layout, Button, message, Modal } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import "./App.css";
import "leaflet/dist/leaflet.css";
import { isMobile } from "react-device-detect";
import logo from "./assets/logo.webp";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";

var dataJsonFile = {}; // Immutable original copy
var dataJson = {}; // Mutable copy

delete LIcon.Default.prototype._getIconUrl;

LIcon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const matchID = /\((.*?)\)/;

var previousID = "";

const ipAdress =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:8080" : "/api";
//const ipAdress = "https://parkaholic.sg/api";
window.ipAddress = ipAdress;

const iconEntrance = new LdivIcon({
  className: "carparkE-div-icon",
  html: `<div class='carparkE-div'><img src='${
    require("./carparkE.svg").default
  }' class='carparkEimg' alt='Carpark Entrance'></div>`,
});

const createClusterCustomIcon = function (cluster) {
  var childCount = cluster.getChildCount();
  const markers = cluster.getAllChildMarkers();

  for (var i = 0; i < markers.length; i++) {
    const name = markers[i].options.className;
    if (name === "CarparkE") {
      childCount -= 1;
    }
  }

  if (childCount > 100) {
    return LdivIcon({
      html: `<span className="circle">${childCount}</span>`,
      className: "marker-cluster-custom-big",
      iconSize: Lpoint(50, 50, true),
    });
  } else if (childCount > 10) {
    return LdivIcon({
      html: `<span>${childCount}</span>`,
      className: "marker-cluster-custom-medium",
      iconSize: Lpoint(40, 40, true),
    });
  } else {
    return LdivIcon({
      html: `<span>${childCount}</span>`,
      className: "marker-cluster-custom-small",
      iconSize: Lpoint(30, 30, true),
    });
  }
};

const Loading = () => {
  return (
    <Layout
      style={{
        display: "flex",
        position: "fixed",
        zIndex: "30",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        textAlign: "center",
      }}
    >
      <div style={{ marginBottom: "1ch" }}>
        <img src={logo} style={{ width: "20ch", height: "20ch" }} alt="Logo" />
      </div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontSize: "4ch",
            color: "white",
            fontWeight: "600",
            marginBottom: "5px",
          }}
        >
          Almost There!
        </p>
        <p style={{ fontSize: "3ch", color: "white", fontWeight: "250" }}>
          Loading Carparks
        </p>
        <span>
          <LoadingOutlined style={{ fontSize: "8ch", color: "#1890ff" }} />
        </span>
      </div>
    </Layout>
  );
};

const ComponentLoading = (useInner = false) => {
  const overLayStyle = {
    fontSize: "600%",
    color: "#177ddc",
    position: "absolute",
    zIndex: 5,
    left: "47%",
    top: "47%",
  };
  const innerStyle = { fontSize: "600%", color: "#177ddc" };
  const innerDivStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "30ch",
  };
  return (
    <div style={useInner ? innerDivStyle : ""}>
      <LoadingOutlined style={useInner ? innerStyle : overLayStyle} />
    </div>
  );
};

const Mobile = React.lazy(() => import("./Mobile.js"));
const Desktop = React.lazy(() => import("./Desktop.js"));
const ReportIncident = React.lazy(() => import("./ReportIncident.js"));
const NewsUpdates = React.lazy(() => import("./NewsUpdates.js"));
const MapPreferenceSelect = React.lazy(() =>
  import("./MapPreferenceSelect.js"),
);
const Credits = React.lazy(() => import("./Credits.js"));

// Main app

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerDesc:
        "A one-stop solution for finding carpark information in Singapore. With Singapore carpark entrance locations, live Singapore carpark lot availability, Singapore parking rates and more for more than 2600 carparks in Singapore!",
      headerTitle: "Parkaholic.sg",
      center: {
        //center of map
        lat: 1.353758,
        lng: 103.825997,
      },
      zoom: 11, //zoom-level of map
      carparkData: [], //filtered carparkData
      parsedMarkers: [], //parsedMarkers are stored here
      selectedCarpark: {
        //Data for the currently selected carpark on the map to be displayed on the left
        ID: "",
        Lots: 0,
        MaxLots: "NA",
        lastUpdated: "N/A",
        Percentage: 0,
        Development: "",
        LotType: "",
        Rates: {
          weekdays_rate_1: "N/A",
          weekdays_rate_2: "",
          saturday_rate: "N/A",
          sunday_publicholiday_rate: "N/A",
          special_remarks: "N/A",
        },
        EntranceLink: "",
        rate_last_checked: "N/A",
        source: "",
      },
      dataJson: {},
      unverifiedLocation: true,
      availabilityData: {},
      maxCapacity: {}, //maxCapacity of carparks
      rates: {}, //rates of carparks
      searchMarkerDisplay: false,
      searchMarkerLocation: { lat: "", lng: "" },
      searchNoResults: false,
      displayInfo: false,
      AvailabilityHistory: [{ Availability: 300, Timing: 1 }],
      ELocations: {},
      EMarkers: [],
      searchQuery: "",
      searchData: "",
      searchLat: [],
      searchLong: [],
      selectOptionNo: 0,
      isRadius: false,
      stillDebouncing: false,
      stillFetching: false,
      editing: false,
      loadingSearch: false,
      loading: true,
      loadingInfo: false,
      mobileDrawer: false,
      mobileDrawerQuery: "",
      modalVisible: false,
      modalButtons: [],
      emptyText: "Start typing to find carparks around a location",
      autocompleteref: null,
      savedCarparksList: [],
      favourited: false,
      time: "",
      currentLocation: {},
      aroundMeButton: false,
      findAroundMe: false,
      filterLotType: "all",
      filterLotTypeLoading: false,
      reportIncidentModal: false,
      newsVisible: false,
      currentNewsVersion: 11,
      mapPreference: "",
      mapPreferenceSelect: false,
      creditsVisible: false,
    };
  }

  componentDidMount() {
    message.config({ maxCount: 2 });

    this.fetchCarparkData(); //Kickstart fetching of data

    /*
    let newsRead = localStorage.getItem('newsRead')
    if (newsRead ) {
      newsRead = JSON.parse(newsRead)
      if (newsRead.currentNewsVersion >= this.state.currentNewsVersion) return true
      else this.setState({ newsVisible: true })
    } 
    else this.setState({ newsVisible: true })*/
  }

  async fetchCarparkData() {
    // Fetch data.json
    const [dataJsonDump, dumpData] = await Promise.all([
      fetch("data.json")
        .then((response) => response.json())
        .catch((error) => message.error("Error loading Parkaholic.sg")),
      fetch(ipAdress + "/dump", {
        method: "GET",
        headers: { accept: "application/json" },
      })
        .then((results) => {
          return results.json(); //return data in JSON (since its JSON data)
        })
        .then(async (data) => data.data)
        .catch((error) => {
          message.error("Oops, error fetching carpark data", 0);
          message.info(
            "Please check your internet connection and refresh the page",
            0,
          );
          console.log(error);
        }),
    ]);
    dataJsonFile = dataJsonDump;
    dataJson = JSON.parse(JSON.stringify(dataJsonFile));
    this.setState({ dataJson: dataJsonFile });

    this.setState(
      { availabilityData: dumpData },
      this.parseCarparkData(dumpData),
    );
    this.loadSavedCarparks(dumpData);
    this.loadMapPreference();
    this.setState({ loading: false }); //Done loading
    message.success({ content: "Welcome to Parkaholic.sg!", duration: 2 });

    const location = this.props.match.params.carparkID;
    if (typeof location === "string") {
      if (location in dataJson) this.handleClick(location, false, dumpData);
      else {
        message.error(
          "Oops. The given carpark ID does not exist. Please check if you have copied the link correctly",
        );
        this.props.history.push("/");
      }
    }
  }

  parseCarparkData(availabilityData) {
    //Parse carpark data into map markers
    var parsedData = [];
    for (const [key, value] of Object.entries(dataJson)) {
      const cords = value.Location.split(" ");
      const parsedCords = [parseFloat(cords[0]), parseFloat(cords[1])];
      if (!parsedCords[0] || !parsedCords[1]) {
        continue;
      }

      var carParkIcon = "";
      if (key in availabilityData) {
        carParkIcon = new LdivIcon({
          className: "carpark-div-icon",
          html: `<span class='carpark-span'>${availabilityData[key].lots}</span>`,
        });
      } else {
        if (value.Development.length > 12) {
          carParkIcon = new LdivIcon({
            className: "carpark-div-icon",
            html: `<div class='carparkLong-div'><img src='${
              require("./carpark.svg").default
            }' class='carparkLong-img' alt='Carpark Entrance'></div>`,
          });
        } else {
          carParkIcon = new LdivIcon({
            className: "carpark-div-icon",
            html:
              "<span class='carpark-span' style='font-size: 95%'>" +
              value.Development +
              "</span>",
          });
        }
      }

      //Parse into leaflet markers
      parsedData.push(
        <Marker
          key={key}
          eventHandlers={{
            click: () => {
              this.handleClick(key);
            },
          }}
          position={parsedCords}
          icon={carParkIcon}
          opacity={1}
        >
          <Popup
            key={key + "P"}
            closeOnClick={false}
            closeButton={false}
            closeOnEscapeKey={false}
            maxWidth={250}
            minWidth={10}
            maxHeight={55}
          >
            {value.Development}
          </Popup>
        </Marker>,
      );
    }
    this.setState({ parsedMarkers: parsedData });
  }

  handleClick = async (
    ID,
    forceUpdate = false,
    availabilityData = this.state.availabilityData,
  ) => {
    //Find the carpark_info object for the given ID
    this.props.history.push("/" + ID);
    const carparkInfo = dataJson[ID];
    const Ecords = dataJson[ID].Location.split(" ");
    this.setState({
      headerTitle: "Parkaholic.sg - " + carparkInfo.Development,
      headerDesc:
        "View carpark rates, entrance and live availability for " +
        carparkInfo.Development +
        ".Parkaholic.sg is a one-stop solution for finding carpark information in Singapore. With Singapore carpark entrance locations, live Singapore carpark lot availability, Singapore parking rates and more for more than 2600 carparks in Singapore!",
    });

    //Get time and set accordingly to set current time line
    var d = new Date();
    let hours = parseInt(d.getHours());
    const minutes = parseInt(d.getMinutes());
    if (minutes > 30) {
      hours += 1;
    }
    if (hours > 23) hours = "00";
    else if (hours < 12) hours = "0" + hours.toString();
    else hours.toString();
    const time = hours + ":00";
    this.setState({ time: time });

    if (isMobile) {
      setTimeout(() => {
        this.setState({
          mobileDrawer: true,
          mobileDrawerQuery: carparkInfo.Development,
        });
      }, 50);
      this.setState({
        center: { lat: Ecords[0] - 0.001, lng: Ecords[1] },
        zoom: 18,
      });
    } else {
      this.setState({ center: { lat: Ecords[0], lng: Ecords[1] }, zoom: 18 });
    }

    this.setState({
      searchMarkerLocation: { lat: Ecords[0], lng: Ecords[1] },
      searchMarkerDisplay: true,
    });

    if (ID !== previousID || forceUpdate) {
      //prevent user from spam-clicking the same carpark

      // Zero-pad the date time string if needed

      let carParkInformation = {
        ID: ID,
        Lots: 0,
        MaxLots: "NA",
        lastUpdated: "N/A",
        Percentage: 0,
        Development: carparkInfo.Development,
        LotType: carparkInfo.LotType,
        Rates: {
          weekdays_rate_1: "N/A",
          weekdays_rate_2: "",
          saturday_rate: "N/A",
          sunday_publicholiday_rate: "N/A",
          special_remarks: "N/A",
        },
        EntranceLink: "",
        rate_last_checked: "N/A",
      };

      let currentIDs = localStorage.getItem("savedCarparks");
      let favourited = false;
      if (typeof currentIDs === "string") {
        currentIDs = JSON.parse(currentIDs);
        if (ID in currentIDs) {
          favourited = true;
        }
      }

      previousID = ID;
      this.setState({
        displayInfo: true,
        isRadius: false,
        favourited: favourited,
        findAroundMe: false,
      });
      this.setState({
        loadingInfo: true,
        EMarkers: [],
        AvailabilityHistory: [{ Availability: 300, Timing: 1 }],
        selectedCarpark: carParkInformation,
        modalButtons: [],
      });

      //Handle AvailableLots (some don't have live data)
      if (ID in availabilityData) {
        carParkInformation.lastUpdated = this.parseUpdatedTimeData(
          availabilityData[ID].lastUpdated,
        );
        carParkInformation.Lots = availabilityData[ID].lots;
      } else {
        carParkInformation.Lots = "No Live Data";
      }

      //Obtain maxCapacity, entrance locations and rates from backend
      const result = await fetch(ipAdress + "/history", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CarParkID: ID,
        }),
      })
        .then((results) => {
          return results.json(); //return data in JSON (since its JSON data)
        })
        .then((data) => {
          return data.resp;
        })
        .catch((error) => {
          message.error("Oops, error fetching carpark history data");
        });

      //Fetch carpark history data
      //History data
      let unverifiedLocation = false;

      if (result !== "given CarParkID not found") {
        var CarparkHistory = [];

        if ("history" in result) {
          for (const i in result.history) {
            var timing = i;
            if (i < 10) {
              timing = "0" + String(i) + ":00";
            } else {
              timing = String(i) + ":00";
            }

            CarparkHistory.push({
              Availability: result.history[i],
              Timing: timing,
            });
          }
        }

        if ("last_updated" in result) {
          const dateObj = new Date(result.last_updated);
          carParkInformation.rate_last_checked =
            ("0" + dateObj.getDate()).slice(-2) +
            "/" +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "/" +
            dateObj.getFullYear();
        }

        //Rates
        if ("weekdays_rate_2" in result) {
          carParkInformation.Rates = {
            weekdays_rate_1: result.weekdays_rate_1,
            weekdays_rate_2: result.weekdays_rate_2,
            saturday_rate: result.saturday_rate,
            sunday_publicholiday_rate: result.sunday_publicholiday_rate,
            special_remarks:
              "special_remarks" in result ? result.special_remarks : "N/A",
          };
        }

        if ("source" in result) carParkInformation.source = result.source;

        //Entrances
        //Append entrance location google maps link
        var entranceMarkers = [];
        var modalButtonsList = [];

        let mapLink = "https://www.google.com/maps/search/?api=1&query=";
        if (this.state.mapPreference === "apple")
          mapLink = "http://maps.apple.com/?daddr=";
        else if (this.state.mapPreference === "waze")
          mapLink = "https://www.waze.com/ul?ll=";
        else if (this.state.mapPreference === "petal")
          mapLink = "https://www.petalmaps.com/place/?z=15&marker=";
        if ("CEL" in result) {
          for (var j = 0; j < result["CEL"].length; j++) {
            if (result["CEL"][j] !== "") {
              const link = encodeURI(mapLink + result["CEL"][j]);
              carParkInformation.EntranceLink = link;

              const Ecords = result["CEL"][j].split(", ");
              const parsedEcords = [
                parseFloat(Ecords[0]),
                parseFloat(Ecords[1]),
              ];

              entranceMarkers.push(
                <Marker
                  className="CarparkE"
                  key={ID + "E" + j}
                  eventHandlers={{
                    click: () => {
                      this.handleClick(ID);
                    },
                  }}
                  position={parsedEcords}
                  icon={iconEntrance}
                  opacity={1}
                >
                  <Popup
                    key={ID + "EP" + j}
                    closeOnClick={false}
                    closeButton={false}
                    closeOnEscapeKey={false}
                    maxWidth={180}
                    minWidth={10}
                    maxHeight={60}
                  >
                    {carparkInfo.Development + " Entrance " + (j + 1) + " ⇌"}
                  </Popup>
                </Marker>,
              );

              modalButtonsList.push(
                <Button
                  type="primary"
                  key={ID + "B" + j}
                  style={{ marginBottom: "1.5vh" }}
                  onClick={() => {
                    var win = window.open(link, "_blank");
                    win.focus();
                  }}
                >
                  {carparkInfo.Development + " Entrance " + (j + 1) + " ⇌"}
                </Button>,
              );
            } else {
              modalButtonsList = [];
              break;
            }
          }
        } else {
          const Ecords = dataJson[ID].Location.split(" ");
          unverifiedLocation = true;
          carParkInformation.EntranceLink = encodeURI(
            mapLink + Ecords[0] + ", " + Ecords[1],
          );
        }

        //maxCapacity
        if ("maxCapacity" in result) {
          carParkInformation.MaxLots = result.maxCapacity;
          carParkInformation.Percentage = this.calculatePercentageFull(
            carParkInformation.Lots,
            result.maxCapacity,
          ); //Calculate AvailableLots/maxLots percentage
        }
      }
      await this.setStateAsync({
        unverifiedLocation: unverifiedLocation,
        AvailabilityHistory: CarparkHistory,
        selectedCarpark: carParkInformation,
        EMarkers: entranceMarkers,
        modalButtons: modalButtonsList,
        loadingInfo: false,
      });
    } else {
      this.setState({ displayInfo: true, isRadius: false });
    }

    return;
  };

  // Use this function if you need to wait for setState to finish before continuting
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  openCarparkEntranceLink() {
    if (this.state.modalButtons.length > 0) {
      this.setState({ modalVisible: true });
    } else {
      var win = window.open(this.state.selectedCarpark.EntranceLink, "_blank");
      win.focus();
    }
  }

  calculatePercentageFull(currentLots, maxLots) {
    const percentage = Math.round((currentLots / maxLots) * 100);
    return percentage;
  }

  callbackSearch = (value, lat, long) => {
    //Callback to get autocomplete search data
    //console.log('WORK FOR F SACKE')
    if (this.searchQuery !== "") {
      this.setState({
        searchData: value,
        searchLat: lat,
        searchLong: long,
        stillFetching: false,
      });
      //console.log('Finished fetching')
    }
  };

  changeQuery = (value) => {
    //To allow user to enter and search for a place
    //console.log(value)
    if (value === "") {
      this.setState({
        searchData: [],
        searchQuery: "",
        isRadius: false,
        displayInfo: false,
      });
    } else {
      this.setState({
        zoom: 11,
        isRadius: false,
        stillFetching: true,
        editing: true,
        displayInfo: false,
        searchQuery: value,
      });
      //this.debounceSearch(value)
    }
    if (this.state.findAroundMe) {
      this.setState({ findAroundMe: false });
    }

    //console.log(value)//debugging check, take out if too noisy
  };

  refocus = async (optionNo) => {
    /*if (this.state.searchQuery === '') { ----> No longer needed since value can only be selected from autocomplete, and it wont be empty
      message.info("Please type something")
    }*/
    //If specific location, zooms into it, activate nearby carparks info and re centers map to it
    let lat = this.state.searchLat;
    let long = this.state.searchLong;
    if (isMobile) {
      this.setState({
        center: { lat: lat[optionNo] - 0.001, lng: long[optionNo] },
        zoom: 18,
        isRadius: true,
        displayInfo: false,
      });
    } else {
      this.setState({
        center: { lat: lat[optionNo], lng: long[optionNo] },
        zoom: 18,
        isRadius: true,
        displayInfo: false,
      });
    }
    this.setState({
      searchMarkerLocation: { lat: lat[optionNo], lng: long[optionNo] },
      searchMarkerDisplay: true,
      findAroundMe: false,
    });

    previousID = "";
  };

  callbackInfo = (lat, long, id) => {
    this.setState({ center: { lat: lat, lng: long } });
    this.handleClick(id);
  }; //Re-centers map to nearby carpark user clicked

  modifySearchData = () => {
    if (this.state.searchQuery.length === 0) {
      if (this.state.searchNoResults === true) {
        this.setState({
          searchNoResults: false,
          emptyText: "Start typing to find carparks around a location",
        });
      }
      return [];
    } else if (
      this.state.searchQuery.length !== 0 &&
      this.state.searchData.length === 0 &&
      !this.state.isRadius &&
      !this.state.stillDebouncing &&
      !this.state.stillFetching
    ) {
      if (this.state.searchNoResults === false) {
        this.setState({
          searchNoResults: true,
          emptyText: "Oops, unable to find that location ❗",
        });
      }
    } else {
      if (this.state.searchNoResults === true) {
        this.setState({
          searchNoResults: false,
          emptyText: "Start typing to find carparks around a location",
        });
      }

      let results = [];
      for (let i = 0; i < this.state.searchData.length; i++) {
        results = [...results, { value: this.state.searchData[i] }];
      }
      return results;
    }
  };

  selectItem = async (value, option) => {
    var OptionNum = 0;

    if (value.indexOf("🅿") !== -1) {
      this.handleClick(value.match(matchID)[1]);
    } else {
      //Find option number (since AntD does not provide it)
      for (var i = 0; i < this.state.searchData.length; i++) {
        if (this.state.searchData[i] === value) {
          this.setState({ selectOptionNo: i });
          OptionNum = i;

          this.refocus(OptionNum);
          break;
        }
      }
    }
  };

  refreshOneCarpark() {
    // need to tag a last updated time to each carpark
    const carparkID = previousID;
    this.setState({ loadingInfo: true });

    fetch(ipAdress + "/search", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        CarParkID: carparkID,
      }),
    })
      .then((results) => {
        return results.json(); //return data in JSON (since its JSON data)
      })
      .then((data) => {
        //Update the lot availability of the current carpark
        const carParkInformation = this.state.selectedCarpark;

        carParkInformation.Lots = data.resp.AvailableLots;
        carParkInformation.lastUpdated = this.parseUpdatedTimeData(
          data.lastUpdated,
        );

        //Update the overall availability data too
        const availabilityData = this.state.availabilityData;
        availabilityData[carparkID].lots = data.resp.AvailableLots;
        availabilityData[carparkID].lastUpdated = data.lastUpdated;

        //Set state
        this.setState({
          availabilityData: availabilityData,
          selectedCarpark: carParkInformation,
          loadingInfo: false,
        });
        message.success(
          'Refreshed carpark "' + dataJson[carparkID].Development + '"',
        );
      })
      .catch((error) => {
        console.log(error);
        message.error("Oops, error refreshing carpark availability");
      });
  }

  addToFavourite() {
    let currentIDs = localStorage.getItem("savedCarparks");
    if (typeof currentIDs !== "string") {
      currentIDs = {};
    } else {
      currentIDs = JSON.parse(currentIDs);
    }

    if (previousID in currentIDs) {
      message.warn("This carpark has already been added to your favourites");
    } else {
      currentIDs[previousID] = "";
      localStorage.setItem("savedCarparks", JSON.stringify(currentIDs));
      message.success(
        'Successfully added carpark "' +
          String(dataJson[previousID].Development) +
          '" to your favourites',
      );
      this.setState({ favourited: true });
      this.loadSavedCarparks(this.state.availabilityData);
    }
  }

  loadSavedCarparks(availabilityData) {
    let savedCarparks = JSON.parse(localStorage.getItem("savedCarparks"));
    //console.log(savedCarparks)
    if (savedCarparks !== undefined && savedCarparks !== null) {
      let savedCarparksList = [];
      for (const [key, value] of Object.entries(savedCarparks)) {
        let availabilityInfo = "";
        if (key in availabilityData) {
          availabilityInfo = (
            <span style={{ color: "#d89614" }}>
              <u>{availabilityData[key].lots}</u> Lots Available
            </span>
          );
        } else {
          availabilityInfo = (
            <span style={{ color: "#d89614" }}>No Live Data</span>
          );
        }
        savedCarparksList.push(
          <li key={key} style={{ cursor: "pointer" }}>
            <div
              className="nearbyCarpark"
              onClick={() => {
                this.handleClick(key);
              }}
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                margin: "10px 5px 5px 5px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#1890ff",
                borderRadius: "5px",
                boxShadow: "0 0 5px #252525",
              }}
            >
              <div>
                <h4>
                  {dataJson[key].Development} - {availabilityInfo}
                </h4>
              </div>
              <div style={{ marginLeft: "1vw" }}>
                <Button
                  type="primary"
                  danger
                  shape="round"
                  icon={<CloseOutlined />}
                  onClick={(event) => {
                    this.deleteSavedCarpark(event, key);
                  }}
                />
              </div>
            </div>
          </li>,
        );
      }

      this.setState({ savedCarparksList: savedCarparksList });
    }
  }

  deleteSavedCarpark(event, ID) {
    event.stopPropagation();
    let savedCarparks = JSON.parse(localStorage.getItem("savedCarparks"));
    delete savedCarparks[ID];
    localStorage.setItem("savedCarparks", JSON.stringify(savedCarparks));
    message.success(
      'Removed carpark "' + dataJson[ID].Development + '" from your favourites',
    );
    this.loadSavedCarparks(this.state.availabilityData);
  }

  //If LocateControl is turned on, this callback function will be called if the target moves (i.e location changes)
  //LocateControl updates the location every 1-2 secs, but handleLocationFound will not call this function if there is no change to location
  updateCurrentLocation(location) {
    this.setState({ currentLocation: location });
    if (
      this.state.aroundMeButton ||
      (this.state.isRadius && !this.state.displayInfo)
    )
      this.setState({
        searchMarkerLocation: {
          lat: location.latitude,
          lng: location.longitude,
        },
      });
  }

  toggleCarparksAroundMe() {
    if (this.state.aroundMeButton === false)
      this.setState({ aroundMeButton: true });
    else this.setState({ aroundMeButton: false });
    this.findCarparksAroundMe(false);
  }

  findCarparksAroundMe = async (setOption) => {
    let lat = 1.353758;
    let long = 103.825997;
    if (
      "latitude" in this.state.currentLocation &&
      "longitude" in this.state.currentLocation
    ) {
      lat = this.state.currentLocation.latitude;
      long = this.state.currentLocation.longitude;
    }

    this.setState({ findAroundMe: setOption });

    if (setOption === true) {
      if (isMobile)
        this.setState({
          center: { lat: lat - 0.001, lng: long },
          zoom: 18,
          isRadius: true,
          displayInfo: false,
        });
      else
        this.setState({
          center: { lat: lat, lng: long },
          zoom: 18,
          isRadius: true,
          displayInfo: false,
        });
      this.setState({
        searchMarkerLocation: { lat: lat, lng: long },
        searchMarkerDisplay: true,
      });
    } else {
      this.setState({ isRadius: false, searchMarkerDisplay: false });
    }

    previousID = "";
  };

  handleFilterLotType = (filterLotType) => {
    dataJson = JSON.parse(JSON.stringify(dataJsonFile));
    if (filterLotType === "all") {
      this.parseCarparkData(this.state.availabilityData);
      message.success({ content: "Showing All Carparks" });
    } else {
      for (const [key, value] of Object.entries(dataJson)) {
        const lotTypes = value.LotType.split("/");
        let existingLotType = false;
        for (let i = 0; i < lotTypes.length; i++) {
          if (filterLotType === lotTypes[i]) {
            existingLotType = true;
            break;
          }
        }
        if (!existingLotType) delete dataJson[key];
      }
      this.parseCarparkData(this.state.availabilityData);
      message.success({ content: "Filtered to " + filterLotType + " lots." });
    }
    this.setState({ filterLotTypeLoading: false });
  };

  reportDataInaccuracy = () => {
    this.setState({ reportIncidentModal: true });
  };

  reportDataInaccuracyClose = () => {
    this.setState({ reportIncidentModal: false });
  };

  handleNewsClose() {
    let currentNewsVersion = {
      currentNewsVersion: this.state.currentNewsVersion,
    };
    localStorage.setItem("newsRead", JSON.stringify(currentNewsVersion));
    this.setState({ newsVisible: false });
  }

  loadMapPreference() {
    //Load map preference (google, apple)
    let mapPreference = localStorage.getItem("map");
    if (typeof mapPreference === "string") {
      this.setState({ mapPreference: mapPreference });
    }
  }

  closeMapPreference = async (map) => {
    localStorage.setItem("map", map);
    this.setState({ mapPreferenceSelect: false });
    if (this.state.mapPreference !== map) {
      this.setState({ mapPreference: map });

      if (
        this.state.mobileDrawer &&
        this.state.displayInfo &&
        !this.state.isRadius
      ) {
        await this.handleClick(this.state.selectedCarpark.ID, true);
        this.openCarparkEntranceLink();
      }
      message.success(
        "Map Preference changed to " +
          map.charAt(0).toUpperCase() +
          map.slice(1),
      );
    }
  };

  settingsOpen = (item) => {
    if (item.key === "settings") this.setState({ mapPreferenceSelect: true });
    else if (item.key === "credits") this.setState({ creditsVisible: true });
  };

  parseUpdatedTimeData = (timeString) => {
    const date = new Date(timeString);
    let hours = date.getHours().toString();
    if (hours.length === 1) hours = "0" + hours;
    let mins = date.getMinutes().toString();
    if (mins.length === 1) mins = "0" + mins;
    let secs = date.getSeconds().toString();
    if (secs.length === 1) secs = "0" + secs;

    return hours + ":" + mins + ":" + secs;
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.state.headerTitle}</title>
          <meta name="description" content={this.state.headerDesc} />
        </Helmet>

        {/*Loading Page*/}
        {this.state.loading && <Loading />}

        {/*Utility components*/}
        {this.state.creditsVisible && (
          <Suspense fallback={<ComponentLoading />}>
            <Credits
              openNews={() => {
                this.setState({ newsVisible: true });
                this.setState({ creditsVisible: false });
              }}
              closeCredits={() => {
                this.setState({ creditsVisible: false });
              }}
            />
          </Suspense>
        )}

        <Modal
          style={{ textAlign: "center" }}
          footer={null}
          title={null}
          open={this.state.mapPreferenceSelect}
          onCancel={() => {
            this.setState({ mapPreferenceSelect: false });
          }}
        >
          <Suspense fallback={<ComponentLoading />}>
            <MapPreferenceSelect
              state={this.state}
              setState={this.setState.bind(this)}
              closeMapPreference={this.closeMapPreference.bind(this)}
            />
          </Suspense>
        </Modal>

        {!this.state.loading && (
          <Modal
            centered
            style={{ textAlign: "center" }}
            title={null}
            open={this.state.newsVisible}
            footer={
              <Button type="primary" onClick={this.handleNewsClose.bind(this)}>
                I got it!
              </Button>
            }
            onCancel={this.handleNewsClose.bind(this)}
          >
            <Suspense fallback={<ComponentLoading />}>
              <NewsUpdates
                newsVisible={this.state.newsVisible}
                handleNewsClose={this.handleNewsClose.bind(this)}
              />
            </Suspense>
          </Modal>
        )}

        {this.state.reportIncidentModal && (
          <Suspense fallback={<ComponentLoading />}>
            <ReportIncident
              reportIncidentModal={this.state.reportIncidentModal}
              reportDataInaccuracyClose={this.reportDataInaccuracyClose.bind(
                this,
              )}
              carparkID={previousID}
              carparkInfo={dataJson[previousID]}
            />
          </Suspense>
        )}

        {/*Main App*/}

        <Suspense fallback={<Loading />}>
          {isMobile ? (
            <Mobile
              state={this.state}
              setState={this.setState.bind(this)}
              settingsOpen={this.settingsOpen.bind(this)}
              openCarparkEntranceLink={this.openCarparkEntranceLink.bind(this)}
              createClusterCustomIcon={createClusterCustomIcon}
              findCarparksAroundMe={this.findCarparksAroundMe.bind(this)}
              updateCurrentLocation={this.updateCurrentLocation.bind(this)}
              callbackInfo={this.callbackInfo.bind(this)}
              callbackSearch={this.callbackSearch.bind(this)}
              handleFilterLotType={this.handleFilterLotType.bind(this)}
              modifySearchData={this.modifySearchData.bind(this)}
              changeQuery={this.changeQuery.bind(this)}
              selectItem={this.selectItem.bind(this)}
              addToFavourite={this.addToFavourite.bind(this)}
              refreshOneCarpark={this.refreshOneCarpark.bind(this)}
              reportDataInaccuracy={this.reportDataInaccuracy.bind(this)}
              previousID={previousID}
              dataJson={this.state.dataJson}
            />
          ) : (
            <Desktop
              state={this.state}
              setState={this.setState.bind(this)}
              settingsOpen={this.settingsOpen.bind(this)}
              openCarparkEntranceLink={this.openCarparkEntranceLink.bind(this)}
              createClusterCustomIcon={createClusterCustomIcon}
              findCarparksAroundMe={this.findCarparksAroundMe.bind(this)}
              updateCurrentLocation={this.updateCurrentLocation.bind(this)}
              toggleCarparksAroundMe={this.toggleCarparksAroundMe.bind(this)}
              callbackInfo={this.callbackInfo.bind(this)}
              callbackSearch={this.callbackSearch.bind(this)}
              handleFilterLotType={this.handleFilterLotType.bind(this)}
              modifySearchData={this.modifySearchData.bind(this)}
              changeQuery={this.changeQuery.bind(this)}
              selectItem={this.selectItem.bind(this)}
              addToFavourite={this.addToFavourite.bind(this)}
              refreshOneCarpark={this.refreshOneCarpark.bind(this)}
              reportDataInaccuracy={this.reportDataInaccuracy.bind(this)}
              previousID={previousID}
              dataJson={this.state.dataJson}
            />
          )}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(App);
