import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <HelmetProvider>
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' render={(props) => <App {...props} />} />
                    <Route exact path='/:carparkID' render={(props) => <App {...props} />} />
                </Switch>
            </BrowserRouter>
        </ConfigProvider>
    </HelmetProvider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
